.Toy {
  position: absolute;
  margin-bottom: 24px;
  overflow: hidden;
  color: #000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: grey;
}

.ToyContainer {
  width: 100%;
  padding-top: 100%;
  max-width: 500px;
  position: relative;
  margin: auto;
}
