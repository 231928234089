.BlinkerToy {
  height: 100%;
  width: 100%;
}

.BlinkerImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.BlinkerCaption {
  position: absolute;
  bottom: 30%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 18px;
  pointer-events: none;
}