.ScratchCanvas {
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  position: relative;
}

.ScratchCaption {
  position: absolute;
  bottom: 20%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
}

.ScratchImg {
  display: none;
}

.ScratchContainer {
  width: 100%;
  height: 100%
}

.ScratchText {
  position: absolute;
  bottom: 20%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
}