.TwoImageTool {
  text-align: center;
}

.btnUpload {
  width: 100%;
  height: 50px;
  background-color: darkviolet;
  color: white;
  border: none;
  font-size: 24px;
}

.btnUpload:disabled {
  background-color: grey;
}

.imgDemo {
  max-height: 200px;
}

.ToolPreview {
  margin-bottom: 12px;
}