.App {
  overflow-x: hidden;
  -webkit-user-select: none;
  max-width: 500px;
  margin: auto;
  margin-bottom: 64px;
}

a {
  text-decoration: none;
  color: inherit;
}


h1 {
  font-family: 'Gochi Hand', cursive;
  font-size: 36px;
  color: #f442b0;
}

.MainFab {
  position: fixed;
  bottom: 8px;
  right: 8px;
}