.ToyFooter {
  text-align: center;
}

.TxtUrl {
  width: 100%;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: none;
  padding: 0px;
}

.BtnCopy {
  height: 50px;
  text-align: center;
  width: 100%;
  border: none;
  background-color: #0093ff;
  color: white;
  font-size: 24px;
}

.BtnCopy:active {
  background-color: #095d9a;
}