body {
  margin: 0;
  padding: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.App {
  overflow-x: hidden;
  -webkit-user-select: none;
  max-width: 500px;
  margin: auto;
  margin-bottom: 64px;
}

a {
  text-decoration: none;
  color: inherit;
}


h1 {
  font-family: 'Gochi Hand', cursive;
  font-size: 36px;
  color: #f442b0;
}

.MainFab {
  position: fixed;
  bottom: 8px;
  right: 8px;
}
.Toy {
  position: absolute;
  margin-bottom: 24px;
  overflow: hidden;
  color: #000;
  width: 100%;
  height: 100%;
  top: 0px;
  left: 0px;
  background-color: grey;
}

.ToyContainer {
  width: 100%;
  padding-top: 100%;
  max-width: 500px;
  position: relative;
  margin: auto;
}

.ScratchCanvas {
  width: 100%;
  height: 100%;
  display: block;
  background-size: cover;
  position: relative;
}

.ScratchCaption {
  position: absolute;
  bottom: 20%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
}

.ScratchImg {
  display: none;
}

.ScratchContainer {
  width: 100%;
  height: 100%
}

.ScratchText {
  position: absolute;
  bottom: 20%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
}
.BlinkerToy {
  height: 100%;
  width: 100%;
}

.BlinkerImg {
  object-fit: cover;
  width: 100%;
  height: 100%;
}

.BlinkerCaption {
  position: absolute;
  bottom: 30%;
  background-color: #000000bb;
  width: 100%;
  color: white;
  text-align: center;
  font-family: sans-serif;
  padding-top: 2px;
  padding-bottom: 2px;
  font-size: 18px;
  pointer-events: none;
}
.BasicToy {
  width: 100%;
  height: 100%;
}
.ToyFooter {
  text-align: center;
}

.TxtUrl {
  width: 100%;
  height: 50px;
  font-size: 18px;
  text-align: center;
  border: none;
  padding: 0px;
}

.BtnCopy {
  height: 50px;
  text-align: center;
  width: 100%;
  border: none;
  background-color: #0093ff;
  color: white;
  font-size: 24px;
}

.BtnCopy:active {
  background-color: #095d9a;
}
.TwoImageTool {
  text-align: center;
}

.btnUpload {
  width: 100%;
  height: 50px;
  background-color: darkviolet;
  color: white;
  border: none;
  font-size: 24px;
}

.btnUpload:disabled {
  background-color: grey;
}

.imgDemo {
  max-height: 200px;
}

.ToolPreview {
  margin-bottom: 12px;
}
